<template>

  <div class="container">
    <ais-instant-search
        :search-client="searchClient"
        index-name="plans"
    >
      <ais-configure
          snippetEllipsisText="…"
      />

      <!-- Slider Component Container -->
      <div class="flex flex-col items-center justify-center mt-24">
        <div class="flex flex-col">
          <!-- Page Scroll Progress -->


          <!-- Navbar -->
          <nav class="flex justify-around py-4 bg-white/80
            backdrop-blur-md shadow-md w-full
            fixed top-0 left-0 right-0 z-10">

            <!-- Logo Container -->
            <div class="flex items-center">
              <!-- Logo -->
              <a class="cursor-pointer">
                <h3 class="text-2xl font-medium text-blue-500">
                  KOTH Carrier DB v.1
                  <!--                    <img class="h-10 object-cover"-->
                  <!--                         src="https://stackoverflow.design/assets/img/logos/so/logo-stackoverflow.svg" alt="Store Logo">-->
                </h3>
              </a>
            </div>

            <!-- Links Section -->
            <div class="items-center hidden space-x-8 lg:flex">
              <a class="flex text-gray-600 hover:text-blue-500
                    cursor-pointer transition-colors duration-300">
                Home
              </a>

              <a class="flex text-gray-600
                    cursor-pointer transition-colors duration-300
                    font-semibold text-blue-600">
                Company
              </a>

              <a class="flex text-gray-600 hover:text-blue-500
                    cursor-pointer transition-colors duration-300">
                Carrier
              </a>

              <a class="flex text-gray-600 hover:text-blue-500
                    cursor-pointer transition-colors duration-300">
                Pricing
              </a>

              <a class="flex text-gray-600 hover:text-blue-500
                    cursor-pointer transition-colors duration-300">
                Features
              </a>

              <a class="flex text-gray-600 hover:text-blue-500
                    cursor-pointer transition-colors duration-300">
                About Us
              </a>
            </div>

            <!-- Icon Menu Section -->
            <div class="flex items-center space-x-5">


              <!-- Search Box -->
              <a class="flex text-gray-600
                    cursor-pointer transition-colors duration-300
                     text-blue-600">

                <app-debounced-search-box :delay="10" class="ais-SearchBox-input" />
              </a>
            </div>
          </nav>
        </div>

        <!-- Notes -->

      </div>



      <div class="search-panel__filters">
        <div class="font-semibold text-xl">Company</div>
        <ais-refinement-list attribute="plancompany" />
        <div class="font-semibold text-xl">Carrier</div>
        <ais-refinement-list attribute="plancarrier" />
        <div class="font-semibold text-xl">Plan Cost</div>

        <br>

        <ais-range-input attribute="plancost">

          <template v-slot="{ setValue }">
            <vue-slider
                :value="internalValue"
                :min="0"
                :max="100"
                :lazy="true"
                @change="(newVal) => handleChange(newVal, setValue)"
            />
          </template>
        </ais-range-input>

        <br>
        <ais-clear-refinements />
        <ais-stats />
      </div>

      <div class="search-panel__results">
<!--        <ais-hits-per-page-->
<!--            :items="[-->
<!--    { label: '50 hits per page', value: 50, default: true },-->
<!--    { label: '100 hits per page', value: 100 },-->
<!--  ]" class="mb-2"-->
<!--        />-->
        <ais-sort-by
            :items="[
    { value: 'plans', label: 'Relevant' },
    { value: 'plans:planpergb:asc', label: 'Price Per GB Asc' },
    { value: 'plans:planpergb:desc', label: 'Price Per GB Desc' },
    ]"
        />
        <ais-current-refinements />


        <ais-hits>
          <template v-slot="{ items }">
            <div class="overflow-hidden bg-white shadow sm:rounded-md">
              <ul role="list" class="divide-y divide-gray-200">
                <li v-for="item in items" :key="item.objectID" class="bg-white px-4 py-4 shadow">
                  <!--
                  Cut Here
                  -->

                  <div class="flex justify-center">
                    <div class="justify-center w-11/12">
                      <div class="border-2 border-gray-200/80 flex flex-row p-3 rounded w-full">
                        <!-- Carrier Container -->
                        <div class="place-items-center place-self-center w-32">
                          <div>
                            {{ item.plancarrier }}
                          </div>
                        </div>
                        <div class="flex flex-col px-6">
                          <div class="flex h-8 flex-row">
                            <!-- Plan Name -->
                            <a :href="item.planurl"> <div class="font-semibold text-xl">{{ item.planname }}</div> </a>
                          </div>
                          <!-- Meta Badges -->
                          <div class="my-2 flex flex-row space-x-2">
                            <!-- Badge Role -->
                            <div class="flex flex-row">
                              <svg class="mr-1 h-10 w-10 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg">
                                <g id="phone_x2C__mobile_x2C__wifi_x2C__connection_x2C__smartphone">
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <path d="M18.5,4h-5C13.224,4,13,3.776,13,3.5S13.224,3,13.5,3h5C18.776,3,19,3.224,19,3.5S18.776,4,18.5,4z" fill="#263238"/>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <path d="M16.5,29h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5         S16.776,29,16.5,29z" fill="#263238"/>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g>
                                        <g>
                                          <path d="M21.5,31h-11C9.121,31,8,29.879,8,28.5v-25C8,2.121,9.121,1,10.5,1h11C22.879,1,24,2.121,24,3.5v20       c0,0.276-0.224,0.5-0.5,0.5S23,23.776,23,23.5v-20C23,2.673,22.327,2,21.5,2h-11C9.673,2,9,2.673,9,3.5v25       c0,0.827,0.673,1.5,1.5,1.5h11c0.827,0,1.5-0.673,1.5-1.5v-7c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v7       C24,29.879,22.879,31,21.5,31z" fill="#263238"/>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <path d="M27.596,20.096c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707    C28.376,18.109,29,16.603,29,15s-0.624-3.109-1.757-4.243c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    C29.272,11.372,30,13.13,30,15c0,1.869-0.728,3.627-2.05,4.949C27.852,20.047,27.724,20.096,27.596,20.096z" fill="#263238"/>
                                    <path d="M25.682,18.682c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707    C26.084,17.073,26.5,16.068,26.5,15s-0.416-2.073-1.171-2.828c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    C26.98,12.409,27.5,13.664,27.5,15s-0.52,2.591-1.464,3.535C25.938,18.633,25.81,18.682,25.682,18.682z" fill="#263238"/>
                                  </g>
                                  <g>
                                    <path d="M4.404,20.096c-0.128,0-0.256-0.049-0.354-0.146C2.728,18.627,2,16.869,2,15c0-1.87,0.728-3.628,2.05-4.95    c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707C3.624,11.891,3,13.397,3,15s0.624,3.109,1.757,4.242    c0.195,0.195,0.195,0.512,0,0.707C4.66,20.047,4.532,20.096,4.404,20.096z" fill="#263238"/>
                                    <path d="M6.318,18.682c-0.128,0-0.256-0.049-0.354-0.146C5.02,17.591,4.5,16.336,4.5,15s0.52-2.591,1.464-3.535    c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707C5.916,12.927,5.5,13.932,5.5,15s0.416,2.073,1.171,2.828    c0.195,0.195,0.195,0.512,0,0.707C6.574,18.633,6.446,18.682,6.318,18.682z" fill="#263238"/>
                                  </g>
                                  <g>
                                    <path d="M21.303,14.696c-0.128,0-0.256-0.049-0.354-0.146c-2.729-2.729-7.17-2.729-9.899,0    c-0.195,0.195-0.512,0.195-0.707,0s-0.195-0.512,0-0.707c3.119-3.119,8.194-3.119,11.313,0c0.195,0.195,0.195,0.512,0,0.707    C21.559,14.647,21.431,14.696,21.303,14.696z" fill="#263238"/>
                                    <path d="M19.536,16.465c-0.128,0-0.256-0.049-0.354-0.146C18.332,15.468,17.202,15,16,15s-2.332,0.468-3.182,1.318    c-0.195,0.195-0.512,0.195-0.707,0s-0.195-0.512,0-0.707C13.149,14.572,14.531,14,16,14s2.851,0.572,3.889,1.611    c0.195,0.195,0.195,0.512,0,0.707C19.792,16.416,19.664,16.465,19.536,16.465z" fill="#263238"/>
                                    <path d="M16.5,20h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5S16.776,20,16.5,20    z" fill="#263238"/>
                                    <path d="M17.768,18.232c-0.128,0-0.256-0.049-0.354-0.146c-0.756-0.756-2.072-0.756-2.828,0    c-0.195,0.195-0.512,0.195-0.707,0s-0.195-0.512,0-0.707c1.133-1.133,3.109-1.133,4.242,0c0.195,0.195,0.195,0.512,0,0.707    C18.023,18.184,17.896,18.232,17.768,18.232z" fill="#263238"/>
                                  </g>
                                </g>
                              </svg>
                              <div class="text-xs text-gray-400/80 hover:text-gray-600">Mobile Hotspot?</div>
                            </div>
                            <!-- Badge Location -->
                            <div class="flex flex-row">
                              <svg class="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z"/>
                              </svg>
                              <div class="text-xs text-gray-400/80 hover:text-gray-400">Tag 2</div>
                            </div>
                            <!-- Badge Email-->
                            <div class="flex flex-row">
                              <svg class="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"/>
                              </svg>
                              <div class="text-xs text-gray-400/80 hover:text-gray-400">Tag 3</div>
                            </div>
                          </div>
                          <!-- Mini Cards -->
                          <div class="mt-2 flex flex-row items-center space-x-5">
                            <!-- Comments -->
                            <a href="#" class="flex h-16 w-28 flex-col items-center justify-center rounded-md border border-dashed border-gray-200 transition-colors duration-100 ease-in-out hover:border-gray-400/80"> <div class="flex flex-row items-center justify-center">
                              <span class="font-bold text-xl text-yellow-500"> ${{ item.plancost }} </span>
                            </div> <div class="mt-2 text-sm text-gray-400">Price</div> </a>
                            <div class="flex flex-row">
                              <svg class="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 42 42" xml:space="preserve">
                            <path d="M37.059,16H26H16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16h10h11.059C39.776,26,42,23.718,42,21
							S39.776,16,37.059,16z"/>
                                <circle cx="21" cy="6" r="5"/>
                                <circle cx="21" cy="36" r="5"/>
                        </svg>
                            </div>
                            <!-- Premium Data -->
                            <a href="#" class="flex h-16 w-28 flex-col items-center justify-center rounded-md border border-dashed border-gray-200 transition-colors duration-100 ease-in-out hover:border-gray-400/80"> <div class="flex flex-row items-center justify-center">
                              <span class="font-bold text-blue-600 text-xl"> {{ item.planhsdata }}GB </span>
                            </div> <div class="mt-2 text-sm text-gray-400">Premium Data</div> </a>
                            <div class="flex flex-row">
                              <svg class="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 42 42" xml:space="preserve">
                            <path d="M4.941,18H16h10h11.059C39.776,18,42,15.718,42,13s-2.224-5-4.941-5H26H16H4.941C2.224,8,0,10.282,0,13S2.224,18,4.941,18z
                                    "/>
                                <path d="M37.059,24H26H16H4.941C2.224,24,0,26.282,0,29s2.224,5,4.941,5H16h10h11.059C39.776,34,42,31.718,42,29
                                    S39.776,24,37.059,24z"/>
                        </svg>
                            </div>
                            <!-- Cost Per GB -->
                            <a href="#" class="flex h-16 w-28 flex-col items-center justify-center rounded-md border border-dashed border-gray-200 transition-colors duration-100 ease-in-out hover:border-gray-400/80"> <div class="flex flex-row items-center justify-center">
                              <span class="font-bold text-green-500 text-xl"> ${{ item.planpergb }} / GB </span>
                            </div> <div class="mt-2 text-sm text-gray-400">of Premium Data</div> </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <!--
                  Cut Here
                  -->
                </li>
              </ul>
            </div>
          </template>
        </ais-hits>
      </div>
      <ais-pagination />

    </ais-instant-search>
  </div>
</template>
<script>

import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"
import AppDebouncedSearchBox from "./DebouncedSearchBox"
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  components: {
    AppDebouncedSearchBox,
    VueSlider,
  },
  data() {
    return {
      searchClient: instantMeiliSearch(
          "https://dms01.sazone.com/",
          "c54c68eac9f9a5ebf89a1fb9138c6acdc97aef570ad84c7327879b1e5287b7fd",
          {
            paginationTotalHits: 400,
            finitePagination: false,
          }
      ),
      internalValue: [0, 100]
    };
  },

  methods: {

    handleChange(newValue, setValue) {
      this.internalValue = newValue;
      setValue(
          {
            start: newValue[0],
            end: newValue[1],
          },
      );
    },

  },


};

</script>
<style>
body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ais-Hits-item img {
  margin-right: 1em;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5em;
}






.container {
  padding: 1rem;
}


.search-panel__filters {
  float: left;
  width: 200px;
}

.search-panel__results {
  margin-left: 210px;
}

</style>


